import axios from 'axios'

const AuthenticationLogin = ( emailVal, passwordVal ) => {
    let payload = JSON.stringify( {
        userId : emailVal,
        password : passwordVal
    } )
    return axios.post(`/authentication/login`, payload )
}

export default AuthenticationLogin