import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface AnButtonProps extends ButtonProps {
  applyNowColor?: string;
}

export const AnButton: React.JSXElementConstructor<any> = styled(
  ({ applyNowColor, ...otherProps }: AnButtonProps) => (
    <Button {...otherProps} />
  )
)`
  flex: 1;
  border-radius: 8px !important;
  font-family: proxima-nova !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  background-color: ${({ applyNowColor }) => applyNowColor};
  color: #fff !important;

  &:hover {
    background-color: ${({ applyNowColor }) => applyNowColor};
  }
`;
