import React, { FC } from "react";
import { Stack } from "@mui/material";
import { SsoButton, SsoButtonText } from "./Styled";

type TextInputProps = {
  mail?: string;
};

export const MicrosoftButton: FC<TextInputProps> = ({ mail }) => {
  return (
    <Stack>
      <SsoButton
        aria-label="Log in with Microsoft"
        variant="outlined"
        sx={{ mb: "12px" }}
        size="large"
        href={`/sso-start?loginMail=${mail}`}
        disableRipple={true}
        startIcon={
          <img
            src="/assets/img/mslogo.png"
            alt="Microsoft Logo"
            style={{ width: 22, height: 22 }}
          />
        }
      >
        <SsoButtonText>Log in with Microsoft</SsoButtonText>
      </SsoButton>
    </Stack>
  );
};
