// @ts-nocheck
import styled from "@emotion/styled";
import {
  createTheme,
  Palette,
  PaletteColor,
  Theme,
} from "@mui/material/styles";

interface HabitatColor extends PaletteColor {
  25: string;
  50: string;
  100: string;
  contrastText: string;
  A100?: string;
  200: string;
  A200?: string;
  light: string;
  300: string;
  400: string;
  A400?: string;
  500: string;
  main: string;
  600: string;
  700: string;
  A700?: string;
  dark: string;
  800: string;
  900: string;
}

// @ts-ignore
interface HabitatPalette extends Palette {
  primary: HabitatColor;
  secondary: HabitatColor;
  gray: HabitatColor;
  grey: HabitatColor;
  neutral: HabitatColor;
  success: HabitatColor;
  warning: HabitatColor;
  error: HabitatColor;
  info: PaletteColor;
  shades: {
    0: string;
    white: string;
    100: string;
    black: string;
  };
}

// @ts-ignore
interface HabitatTheme extends Theme {
  palette: HabitatPalette;
}

// @ts-ignore
export const palette: HabitatPalette = {
  primary: {
    25: "#DBEAFE",
    contrastText: "#DBEAFE",
    50: "#DBEAFE",
    100: "#C1DEFF",
    200: "#8EC3FF",
    light: "#8EC3FF",
    300: "#4A9EFF",
    400: "#2A8AE2",
    500: "#0071F3",
    main: "#0071F3",
    600: "#0062D2",
    700: "#175CD3",
    dark: "#004BA1",
    800: "#202C46",
    900: "#06102B",
  },
  secondary: {
    25: "#FCFAFF",
    contrastText: "#FCFAFF",
    50: "#F9F5FF",
    100: "#F4EBFF",
    200: "#E9D7FE",
    light: "#E9D7FE",
    300: "#D6BBFB",
    400: "#B692F6",
    500: "#9E77ED",
    main: "#9E77ED",
    600: "#7F56D9",
    700: "#6941C6",
    dark: "#6941C6",
    800: "#53389E",
    900: "#42307D",
  },
  grey: {
    25: "#FCFCFD",
    contrastText: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F2F4F7",
    A100: "#F2F4F7",
    200: "#EAECF0",
    A200: "#EAECF0",
    light: "#EAECF0",
    300: "#D0D5DD",
    400: "#98A2B3",
    A400: "#98A2B3",
    500: "#667085",
    main: "#667085",
    600: "#475467",
    700: "#344054",
    A700: "#344054",
    dark: "#344054",
    800: "#1D2939",
    900: "#101828",
  },
  gray: {
    25: "#FCFCFD",
    contrastText: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F2F4F7",
    A100: "#F2F4F7",
    200: "#EAECF0",
    A200: "#EAECF0",
    light: "#EAECF0",
    300: "#D0D5DD",
    400: "#98A2B3",
    A400: "#98A2B3",
    500: "#667085",
    main: "#667085",
    600: "#475467",
    700: "#344054",
    A700: "#344054",
    dark: "#344054",
    800: "#1D2939",
    900: "#101828",
  },
  neutral: {
    25: "#FCFCFD",
    contrastText: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F2F4F7",
    A100: "#F2F4F7",
    200: "#EAECF0",
    A200: "#EAECF0",
    light: "#EAECF0",
    300: "#D0D5DD",
    400: "#98A2B3",
    A400: "#98A2B3",
    500: "#667085",
    main: "#667085",
    600: "#475467",
    700: "#344054",
    A700: "#344054",
    dark: "#344054",
    800: "#1D2939",
    900: "#101828",
  },
  info: {
    contrastText: "#FCFCFD",
    light: "#EAECF0",
    main: "#667085",
    dark: "#344054",
  },
  success: {
    25: "#F6FEF9",
    50: "#ECFDF3",
    contrastText: "#ECFDF3",
    100: "#D1FADF",
    200: "#A6F4C5",
    light: "#A6F4C5",
    300: "#6CE9A6",
    400: "#32D583",
    500: "#12B76A",
    main: "#12B76A",
    600: "#039855",
    700: "#027A48",
    dark: "#027A48",
    800: "#05603A",
    900: "#054F31",
  },
  warning: {
    25: "#FFFCF5",
    contrastText: "#FFFCF5",
    50: "#FFFAEB",
    100: "#FEF0C7",
    200: "#FEDF89",
    light: "#FEDF89",
    300: "#FEC84B",
    400: "#FDB022",
    500: "#F79009",
    main: "#F79009",
    600: "#DC6803",
    700: "#B54708",
    dark: "#B54708",
    800: "#93370D",
    900: "#7A2E0E",
  },
  error: {
    25: "#FFFBFA",
    contrastText: "#FFFBFA",
    50: "#FEF3F2",
    100: "#FEE4E2",
    200: "#FECDCA",
    light: "#FECDCA",
    300: "#FDA29B",
    400: "#F97066",
    500: "#F04438",
    main: "#F04438",
    600: "#D92D20",
    700: "#B42318",
    dark: "#B42318",
    800: "#921018",
    900: "#7A271A",
  },
  shades: {
    0: "#FFFFFF",
    white: "#FFFFFF",
    100: "#000000",
    black: "#000000",
  },
};

export const typography: any = {
  fontFamily: "proxima-nova !important",
  htmlFontSize: 16,
  h1: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 40,
    lineHeight: "48px",
  },
  h2: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 32,
    lineHeight: "40px",
  },
  h3: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 24,
    lineHeight: "32px",
  },
  h4: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "24px",
  },
  h5: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "28px",
  },
  h6: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "28px",
  },
  body1: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "24px",
  },
  body2: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "22px",
  },
  caption: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "16px",
  },
  p2: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
  },
  p4: {
    fontFamily: "proxima-nova !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "16px",
  },
};

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: { color: palette.primary.main },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          borderRadius: 8,
          fontSize: 14,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: { padding: 0, fontSize: 24 },
      },
    },
  },
});

export default theme as HabitatTheme;
