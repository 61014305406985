import React from "react";
import { Link, LinkProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface AnButtonProps extends LinkProps {
  applyNowColor?: string;
}

export const AnLink: React.JSXElementConstructor<any> = styled(
  ({ applyNowColor, ...otherProps }: AnButtonProps) => <Link {...otherProps} />
)`
  color: ${({ applyNowColor }) => applyNowColor} !important;

  &:hover {
    color: ${({ applyNowColor }) => applyNowColor} !important;
  }
`;
