import axios from "axios";

// endpoint for settings page only
export const saveCompanySsoSettings = async (settings) => {
  const payload = new FormData();

  payload.append("settings", JSON.stringify(settings));
  payload.append("authenticityToken", window.AN2_CSRF);

  try {
    const response = await axios.post("/company-sso-settings", payload);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `POST /company-sso-settings responded with status ${response.status}`
      );
    }
  } catch (exception) {
    return exception.response.data;
  }
};

// endpoint to check if email is Azure AD user
export const checkSSOEnabledForUser = async (email, body) => {
  try {
    const response = await axios.post(
      `/ssoEnabledForUserAJAX?mail=${email}`,
      body
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        `POST /ssoEnabledForUserAJAX responded with status ${response.status}`
      );
    }
  } catch (error) {
    console.log(`ERROR in SsoApi.ts: ${error.response.data}`);
    return error.response.data;
  }
};
