export default {
  translation: {
    applyNow: {
      mainCTA: "Apply Now",
      ssoLoginCard: {
        cardHeading: "Start a loan application",
        cardSubheading:
          "Beginning the loan application process? You’re in the right place! Get started by creating an account.",
        confirmEmailInputLabel: "Confirm Email",
        continueButton: "Continue with email",
        emailInputLabel: "Email",
        passwordInputLabel: "Password",
        passwordVisibilityHide: "Hide",
        passwordVisibilityShow: "Show",
      },
    },
    auth: {
      forgotPasswordSuggestive: "Forgot password?",
      greetReturningUser: "Welcome back",
      loginPromptDirect: "Log in with your account.",
      loginPromptSuggestive: "Have an account?",
      loginButton: "Log in",
      noAccountSuggestive: "Don't have an account?",
      passwordRequirements: {
        characters: "characters",
        heading: "Password must contain at least",
        lowerCaseLetters: "lowercase letter",
        numbers: "number",
        specialCharacters: "special character (!, #, %, etc.)",
        upperCaseLetters: "uppercase letter",
      },
      termsAgreementLink: "Terms of Use and Privacy Policy",
      termsAgreementPrefix: "By signing in, you agree to the",
    },
    validation: {
      emailInUse:
        'An account with that email address already exists. Click "Log In" instead.',
      emailMismatch: "Please make sure your email addresses match.",
      emailRequired: "Email is required",
      floifyAddress:
        "You cannot use a floify.com email address. Please use a different email address.",
      invalidEmail: "Invalid Email",
      invalidPassword: "Please enter a valid password.",
      lenderAddress:
        "This email belongs to a lender in Floify and cannot be reused. Enter a different email to continue.",
    },
  },
};
