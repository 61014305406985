import React, { FC, CSSProperties, useContext } from "react";
import styled from "@emotion/styled";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { palette, typography } from ".";

export interface CheckboxGroupProps {
  checked: boolean;
  disabled?: boolean;
  label: string | number;
  onChange: (checked: boolean) => void;
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  label,
  onChange,
  ...otherProps
}) => (
  <FormGroup>
    <FormControlLabel
      control={
        <Checkbox
          disableRipple
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 23,
              color: palette.primary.main,
            },
          }}
          onChange={(e) => onChange(e.target.checked)}
          {...otherProps}
        />
      }
      label={label}
      sx={{
        height: 30,
        marginBottom: 2,
        "& .MuiTypography-root": {
          ...typography.p2,
        },
      }}
    />
  </FormGroup>
);

export default CheckboxGroup;
