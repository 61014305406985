import axios from 'axios'

const AuthenticationRegister = ( emailVal, passwordVal ) => {
    let payload = JSON.stringify( {
        userId : emailVal,
        password : passwordVal
    } )
    let config = {
        headers: {
            utmParams: window.location.search,
            referringPage: document.referrer
        },
    };
    return axios.post(`/authentication/register`, payload, config )
}

export default AuthenticationRegister