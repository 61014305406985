import axios from 'axios'

const CheckForMailErrors = ( email, noPasswordEnabled = false ) => {
    return axios.get('/checkForMailErrors', {
        params : {
            mail : email,
            noPasswordEnabled
        }
    })
    .then(function (response) {
        if( response.status === 200 ){
            return response.data
        }else{
            throw new Error(`GET /checkForMailErrors responded with status ${response.status}`)
        }
    })
    .catch(function (error) {
        console.log( `ERROR : ${error}` )
    });
}

export default CheckForMailErrors