// @ts-nocheck
import React from "react";

import {
  FormControl,
  InputLabel,
  TextFieldProps,
  InputBase,
  FormHelperText,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { SxProps, Theme as MuiTheme } from "@mui/system";

type TextInputProps = TextFieldProps & {
  fcsx?: SxProps<MuiTheme>;
  lblsx?: SxProps<MuiTheme>;
  primaryColor?: string;
  error?: boolean;
};

const TextInput = (props: TextInputProps) => {
  const theme = useTheme();

  return (
    <FormControl
      variant="standard"
      sx={{
        color: theme.palette.grey[600],
        ...props?.fcsx,
      }}
      className={props.error ? "errorHighlight" : ""}
    >
      <StyledLabel
        shrink={true}
        primaryColor={props.primaryColor}
        htmlFor={props?.inputProps?.id}
        sx={{
          transform: "scale(1)",
          fontFamily: "proxima-nova !important",
          ...props?.lblsx,
        }}
      >
        {props.label}
      </StyledLabel>

      {/* @ts-ignore */}
      <StyledInput variant="outlined" size="small" {...props} />

      {props?.helperText && (
        <FormHelperText
          sx={{
            fontSize: 12,
            color: theme.palette.grey[500],
          }}
        >
          {props?.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const StyledLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== "primaryColor",
})<{ primaryColor?: string }>(({ theme, primaryColor }) => ({
  "&.Mui-focused": {
    color: primaryColor || theme.palette.primary.main,
  },
}));

const StyledInput = styled(InputBase, {
  shouldForwardProp: (prop) => !["primaryColor", "error"].includes(prop),
})<{ primaryColor?: string; error?: boolean }>(
  ({ theme, primaryColor, error }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
      fontFamily: "proxima-nova !important",
    },
    "& .MuiInputBase-input": {
      fontFamily: "proxima-nova !important",
      borderRadius: `8px !important`,
      position: "relative",
      border: "1px solid",
      borderColor: error ? "#c31702" : "#ced4da",
      backgroundColor: "transparent",
      fontSize: 14,
      padding: "8px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
      ]),
      "&:focus": {
        borderColor: primaryColor || theme.palette.primary.main,
      },
    },
  })
);

export default TextInput;
