import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { I18nWrapper, ResourcesKey } from "types/i18n";

import { default as en } from "../en";
import { default as es } from "../es";

export const DEFAULT_LANGUAGE: ResourcesKey = "en";

export const LANGUAGE_CHOICE_QUERY_PARAM = "lang";

export const MODAL_ROOT_ID = "translations-root";

const i18nWrapper: I18nWrapper = {
  t: i18n.t.bind(i18n),

  changeLanguage: async (lng: ResourcesKey) => {
    return await i18n.changeLanguage(lng);
  },

  getCurrentLanguage: () => {
    return i18n.language as ResourcesKey;
  },

  exists: (key: string) => {
    return i18n.exists(key);
  },

  getLanguages: () => {
    return Object.keys(i18n.options.resources || {}) as Array<ResourcesKey>;
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false, // react already protects against xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  lng: DEFAULT_LANGUAGE, // main language choice; change this at-will using `changeLanguage`
  resources: {
    en,
    es,
  },
});

export const getLanguageFromURL = () => {
  const queryLanguage = new URLSearchParams(window.location.search).get(
    LANGUAGE_CHOICE_QUERY_PARAM
  ) as ResourcesKey | undefined;

  return queryLanguage ?? DEFAULT_LANGUAGE;
};

export const useLanguage = () => i18nWrapper;
