export default {
  translation: {
    applyNow: {
      mainCTA: "Aplique Ahora",
      ssoLoginCard: {
        cardHeading: "Iniciar una solicitud de préstamo",
        cardSubheading:
          "¿Comenzando el proceso de solicitud de préstamo? ¡Estás en el lugar correcto! Comienza creando una cuenta.",
        confirmEmailInputLabel: "Confirmación de Correo Electrónico",
        continueButton: "Continuar con email",
        emailInputLabel: "Correo Electrónico",
        passwordInputLabel: "Contraseña",
        passwordVisibilityHide: "Ocultar",
        passwordVisibilityShow: "Mostrar",
      },
    },
    auth: {
      forgotPasswordSuggestive: "¿No recuerda su contraseña?",
      greetReturningUser: "Bienvenido de nuevo",
      loginPromptDirect: "Inicia sesión con su cuenta.",
      loginPromptSuggestive: "¿Tiene una cuenta?",
      loginButton: "Inicia sesión",
      noAccountSuggestive: "¿No tiene una cuenta?",
      passwordRequirements: {
        characters: "caracteres",
        heading: "La contraseña debe contener al menos",
        lowerCaseLetters: "letra minúscula",
        numbers: "número",
        specialCharacters: "carácter especial (!, #, %, etc.)",
        upperCaseLetters: "letra mayúscula",
      },
      termsAgreementLink: "Términos de Uso y la Política de Privacidad",
      termsAgreementPrefix: "Al iniciar sesión, usted acepta los",
    },
    validation: {
      emailInUse:
        'Ya existe una cuenta con esa dirección de correo electrónico. Haz clic en "Iniciar Sesión" en su lugar.',
      emailMismatch:
        "Por favor, revise que las direcciones de correo electrónico coincidan.",
      emailRequired: "El correo electrónico es requerido",
      floifyAddress:
        "No puede usar una dirección de correo electrónico de floify.com. Por favor, use una dirección de correo electrónico diferente.",
      invalidEmail: "Correo electrónico no valido",
      invalidPassword: "Por favor ingresa una contraseña válida.",
      lenderAddress:
        "Este correo electrónico pertenece a un prestamista en Floify y no se puede reutilizar. Ingrese un correo electrónico diferente para continuar.",
    },
  },
};
