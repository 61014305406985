import { useLanguage } from "../../../Utils/translations/lib";

import MinimumPasswordReqs from "../../Network/GET/MinimumPasswordReqs";

export const getPasswordRequirements = async () => {
  const {
    passwordMinLength,
    passwordMinLowerChars,
    passwordMinNumbers,
    passwordMinSymbols,
    passwordMinUpperChars,
  } = await MinimumPasswordReqs();

  const { t } = useLanguage();

  return [
    {
      name: `${passwordMinUpperChars} ${t(
        "auth.passwordRequirements.upperCaseLetters"
      )}`,
      pattern: `^(.*[A-Z]){${passwordMinUpperChars},}.*$`,
      min: `${passwordMinUpperChars}`,
    },
    {
      name: `${passwordMinLength} ${t("auth.passwordRequirements.characters")}`,
      pattern: `.{${passwordMinLength}}`,
      min: passwordMinLength,
    },

    {
      name: `${passwordMinLowerChars} ${t(
        "auth.passwordRequirements.lowerCaseLetters"
      )}`,
      pattern: `^(.*[a-z]){${passwordMinLowerChars},}.*$`,
      min: passwordMinLowerChars,
    },
    {
      name: `${passwordMinNumbers} ${t("auth.passwordRequirements.numbers")}`,
      pattern: `^(.*[0-9]){${passwordMinNumbers},}.*$`,
      min: passwordMinNumbers,
    },
    {
      name: `${passwordMinSymbols} ${t(
        "auth.passwordRequirements.specialCharacters"
      )}`,
      pattern: `^(.*[!@#$%^&*(),.?":{}\\[\\]\\|<>]){${passwordMinSymbols},}.*$`,
      min: passwordMinSymbols,
    },
  ];
};

export const validatePassword = (password, requirements) => {
  const valid = requirements
    // We filter to only check the requirements that are actually required
    .filter((requirement) => requirement.min > 0)
    // Then we make sure each requirement is fulfilled
    .every((requirement) =>
      new RegExp(requirement.pattern, "g").test(password)
    );

  return valid;
};
