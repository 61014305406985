import React from "react";
import { styled } from "@mui/material/styles";

export const LoginErrorMessage: React.JSXElementConstructor<any> = styled(
  ({
    marginTop,
    marginBottom,
    ...otherProps
  }: {
    marginTop: number;
    marginBottom: number;
  }) => <div data-testid="login-error-message" {...otherProps} />
)`
  color: #c31702;
  margin-top: ${(props) => props.marginTop ?? 16}px;
  margin-bottom: ${(props) => props.marginBottom ?? 16}px;
`;
