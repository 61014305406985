import React from 'react';
import { createPortal } from 'react-dom';
import usePortal from './usePortal';

/**
 * @example
 * <Portal id="modal">
 *   <p>Thinking with portals</p>
 * </Portal>
 */

const Portal = ({ id, children }) => {
  const target = usePortal(id);
  return createPortal(
    children,
    // @ts-ignore
    target,
  );
};

export default Portal; 