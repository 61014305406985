import axios from 'axios'

const MinimumPasswordReqs = () => {
    const hasCode = window.PARAMS ? window.PARAMS.code : ''
    const code = hasCode ? `?code=${window.PARAMS.code}` : ''
    return axios.get('/authentication/password-requirements' + code)
    .then(function (response) {
        if( response.status === 200 ){
            return response.data
        }else{
            throw new Error(`GET /authentcation/password-requirements responded with status ${response.status}`)
        }
    })
    .catch(function (error) {
        // TODO: come up with a error handling solution
        // maybe integrate with airbrake
        console.log( `ERROR : ${error}` )
    });
}

export default MinimumPasswordReqs