import React, { FC, CSSProperties, useContext } from "react";
import styled from "@emotion/styled";
import { palette } from ".";

// this is NOT approved by the designers!
const IconButton = styled.button`
  border: none;
  background: transparent;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  font-size: 16px;
  color: ${palette.primary.main};
  &:hover {
    background: rgba(25, 118, 210, 0.04);
  }
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background: rgba(25, 118, 210, 0);
  }
`;

export default IconButton;
