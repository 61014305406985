/** @jsx jsx */
import { jsx } from "@emotion/react";
import { typography } from ".";

const defaultElementMap = {
  h4: "h4",
  p2: "p",
  p4: "p",
};

function Text({ variant = "p2", element = "", ...otherProps }) {
  const Component = element || defaultElementMap[variant];

  return <Component css={typography[variant]} {...otherProps} />;
}

export default Text;
