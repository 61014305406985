import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Portal from "./Portal";
import { SsoSettingsPage, SsoLoginCard, SsoUnknownUser } from "../SSO";

function AppTest() {
  const [toggle, setToggle] = useState(false);

  const windowOnload = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    window.addEventListener("load", windowOnload);

    return function cleanup() {
      window.removeEventListener("load", windowOnload);
    };
  }, []);

  if (!toggle) {
    return <div />;
  }

  const {
    settings,
    companySsoProviders,
    isRegistration = false,
    applyNowColorScheme,
    unknownEmail,
    provider,
    applyNowEnabled,
  } = window.PARAMS || {};
  // NOTE: Make sure the ID is unique as this will inject your component in this id globally in the app.
  return (
    <div className="PortalRoot">
      <Portal id="SocialLoginPortal">
        <>
          {companySsoProviders && (
            <SsoLoginCard
              companySsoProviders={companySsoProviders}
              isRegistration={isRegistration}
              primaryColor={applyNowColorScheme}
              applyNowEnabled={applyNowEnabled}
            />
          )}
        </>
      </Portal>
      <Portal id="SsoSettingsPage">
        {/* Not sure how this file is being loaded on pages where it shouldn't, but this gate at least keeps it from rendering */}
        {settings && <SsoSettingsPage initialSettings={settings} />}
      </Portal>
      <Portal id="UnknownUserPortal">
        {unknownEmail && (
          <SsoUnknownUser
            provider={provider}
            email={unknownEmail}
            primaryColor={applyNowColorScheme}
          />
        )}
      </Portal>
    </div>
  );
}

ReactDOM.render(<AppTest />, document.getElementById("AppPortalReactRoot"));
