import React, { FC, CSSProperties, useContext } from "react";
import styled from "@emotion/styled";
import MuiRadio from "@mui/material/Radio";
import MuiRadioGroup from "@mui/material/RadioGroup";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import MuiFormControl from "@mui/material/FormControl";
import MuiFormLabel from "@mui/material/FormLabel";
import { palette, typography } from ".";

type option = string | number;

export interface RadioGroupProps {
  label?: string;
  onChange: (value: option) => void;
  value: option;
  options: option[];
}

const Icon = styled("span")({
  borderRadius: "50%",
  width: 17,
  height: 17,
  border: `1px solid ${palette.neutral[400]}`,
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
});

const CheckedIcon = styled(Icon)({
  border: `5.5px solid ${palette.primary.main}`,
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    content: '""',
  },
});

function Radio(props) {
  return (
    <MuiRadio
      style={{ backgroundColor: "transparent" }}
      disableRipple
      checkedIcon={<CheckedIcon />}
      icon={<Icon />}
      {...props}
    />
  );
}

const RadioGroup: FC<RadioGroupProps> = ({
  label,
  onChange,
  value,
  options,
}) => {
  const name = label || "radio-group";

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <MuiFormControl sx={{ display: "block" }}>
      {label && <MuiFormLabel id={name}>{label}</MuiFormLabel>}
      <MuiRadioGroup
        aria-labelledby={name}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MuiFormControlLabel
            value={option}
            control={<Radio />}
            label={option}
            sx={{
              height: 30,
              marginBottom: 0,
              "& .MuiTypography-root": {
                ...typography.p2,
              },
            }}
          />
        ))}
      </MuiRadioGroup>
    </MuiFormControl>
  );
};

export default RadioGroup;
