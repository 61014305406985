// @ts-nocheck
import { Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SsoButtonText = styled("span")`
  padding-left: 20px;
  text-align: left;
  line-height: 14px;
` as typeof JSX.Element;

export const SsoButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[900]}`,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  height: 42,
  color: theme.palette.grey[900],
  justifyContent: "flex-start",
  textTransform: "none",
  borderRadius: `8px !important`,
  fontSize: `14px !important`,
  fontFamily: "proxima-nova !important",

  ".MuiButton-startIcon": {
    borderRight: `1px solid ${theme.palette.grey[900]}`,
    height: 42,
    paddingLeft: 20, // +4px cause mui overlaps somehow on left hand side
    paddingRight: 16,

    alignItems: "center",
    justifyContent: "center",
  },

  ".MuiButton-text": {
    marginLeft: "auto",
    marginRight: "auto",
    background: "green",
  },

  "&:hover": {
    border: `1px solid ${theme.palette.grey[900]}`,
  },
  "&:active": {
    border: `1px solid ${theme.palette.grey[900]}`,
  },
  "&:focus": {
    border: `1px solid ${theme.palette.grey[900]}`,
  },
})) as typeof Button;
