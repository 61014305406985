import React, { FC, CSSProperties, useContext } from "react";
import styled from "@emotion/styled";

export interface ButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}

// this should probably go away. I tried to match the buttons on settings
// screens that trigger modals
const StyledButton = styled.button<ButtonProps>`
  height: 22px;
  padding: 0 8px;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-family: proxima-nova;
`;

const SmallButton: FC<ButtonProps> = (props) => <StyledButton {...props} />;

export default SmallButton;
