import React from "react";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material";
import styled from "@emotion/styled";

import { palette, typography } from ".";

// TODO: make a real theme and dump a lot of this stuff into it
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#ff0000",
//     },
//   },
// });

// const ThemedButton = (props) => (
//   <ThemeProvider theme={theme}>
//     <MuiButton variant="contained" disableElevation {...props} />
//   </ThemeProvider>
// );

/**
 * MUI Button API -- https://mui.com/api/button/
 *
 * Default button is blue. For gray button, color="info"
 **/
const Button = styled((props: ButtonProps) => (
  <MuiButton variant="contained" disableRipple {...props} />
))<ButtonProps>`
  font-family: ${typography.fontFamily};
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  text-transform: none;
  padding: 0 15px;

  &:hover {
    box-shadow: none;
  }

  // Sizes
  &.MuiButton-sizeMedium {
    height: 35px;
  }

  // Contained Styles
  &.MuiButton-containedPrimary {
    background-color: ${palette.primary.main};
  }

  &.MuiButton-containedPrimary:hover {
    background-color: ${palette.primary[600]};
  }

  &.MuiButton-containedInfo {
    background-color: ${palette.neutral.light};
    color: ${palette.primary[900]};
  }

  &.MuiButton-contained.Mui-disabled {
    opacity: 0.5;
    color: ${palette.shades.white};
    cursor: not-allowed;
    pointer-events: auto;
  }

  // Outlined Styles
  &.MuiButton-outlinedPrimary {
    border: 1px solid ${palette.primary.main};
  }

  &.MuiButton-outlinedPrimary.Mui-disabled {
    border-color: ${palette.neutral.main};
    color: ${palette.neutral.main};
    cursor: not-allowed;
    pointer-events: auto;
  }

  // Text Styles
  &.MuiButton-text:hover,
  &.MuiButton-text:focus,
  &.MuiButton-text:active {
    text-decoration: none;
  }

  &.MuiButton-textPrimary {
    color: ${palette.primary.main};
  }

  &.MuiButton-textInfo {
    color: ${palette.neutral[700]};
  }

  // Text Link Styles
  ${(props) =>
    props.href
      ? `
  &.MuiButton-text {
    padding: 0;
    height: auto;
  }

  &.MuiButton-text:hover {
    background: none;
  }
  `
      : ""}
`;

export default Button;
