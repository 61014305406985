import React, { FC } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { AnButton } from "../assets/Components/Login/AnButton";

export const SsoUnknownUser: FC<{
  email: string;
  provider: string;
  primaryColor: string;
}> = ({ email, provider, primaryColor }) => {
  return (
    <Card
      sx={{
        p: 4,
        maxWidth: 343,
        mb: 3,
        ml: "auto",
        mr: "auto",
      }}
      elevation={3}
    >
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "24px",
        }}
      >
        We don’t recognize this email
      </Typography>

      <Typography
        variant="body2"
        sx={{
          mt: 3,
        }}
      >
        You tried logging in with {email} using single sign-on. We could not
        find a user with that email address in our system.
      </Typography>

      <Stack>
        <AnButton
          sx={{
            mt: 3,
            color: "#fff !important",
          }}
          applyNowColor={primaryColor}
          size="large"
          variant="contained"
          disableRipple={true}
          href="/login"
        >
          Log in with different email
        </AnButton>
        <AnButton
          sx={{
            mt: 1.5,
            borderColor: "#000",
          }}
          size="large"
          variant="outlined"
          disableRipple={true}
          href={`/oidc/register/${provider}`}
        >
          Create a new account
        </AnButton>
      </Stack>
    </Card>
  );
};
