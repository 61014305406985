import React from "react";
import styled from "@emotion/styled";
import { palette, typography } from ".";

// work in progress!
const StyledInput = styled.input`
  font-family: ${typography.fontFamily};
  height: 32px;
  width: 250px;
  border: 1px solid ${palette.neutral[300]};
  border-radius: 4px;
  padding: 0 15px;
  font-size: 16px;
  line-height: 24px;
  color: ${palette.neutral[900]};
  margin-bottom: 12px;
`;

function TextField({ ...props }) {
  return <StyledInput ref={props.inputRef} type="text" {...props} />;
}

export default TextField;
