import React from "react";
import { Dialog } from "@mui/material";
import { Transition } from "react-transition-group";
import styled from "@emotion/styled";

const duration = 300;

const defaultStyle = {
  opacity: 0,
  trasnform: "translate(0, -15%)",
  transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
};

const transitionStyles = {
  entering: { transform: "translate(0, 0)", opacity: 1 },
  entered: { transform: "translate(0, 0)", opacity: 1 },
  exiting: { transform: "translate(0, -15%)", opacity: 0 },
  exited: { transform: "translate(0, -15%)", opacity: 0 },
};

const StyledDialog = styled(Dialog)`
  font-family: proxima-nova;
  font-size: 20px;

  .MuiPaper-root {
    width: 800px;
    max-width: 90%;
    padding: 24px 40px 40px;
    border-radius: 8px;
  }
`;

function ModalTransition({ children, ...props }) {
  return (
    <Transition in={props.in} timeout={props.timeout} {...props}>
      {(state) =>
        React.cloneElement(children, {
          style: {
            ...defaultStyle,
            ...transitionStyles[state],
          },
        })
      }
    </Transition>
  );
}

const defaultHeaderStyles = {
  borderBottom: "1px solid #eaecf0",
};

export default function FloModal({ children, open, headerContent, ...props }) {
  return (
    <StyledDialog open={open} TransitionComponent={ModalTransition} {...props}>
      {headerContent && (
        <div
          className="Header"
          style={props?.headerStyles || defaultHeaderStyles}
        >
          {headerContent}
        </div>
      )}
      {children}
    </StyledDialog>
  );
}
