export const getDataUrl = (image, mimeType) => {
  // Create canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Set width and height
  canvas.width = image.width;
  canvas.height = image.height;

  // Draw the image
  ctx?.drawImage(image, 0, 0);

  return canvas.toDataURL(mimeType);
};
