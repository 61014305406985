import React, { FC, useEffect, useRef, useState } from "react";
import {
  Alert,
  Typography,
  Box,
  ThemeProvider,
  Link,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Divider,
  Button,
  Stack,
  Input,
  Grid,
  ButtonProps,
} from "@mui/material";
import { theme, TextInput } from "../habitat";
import { styled } from "@mui/material/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import {
  faCircleExclamation,
  faXmark,
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { faCircleExclamation as faCircleExclamationDuo } from "@fortawesome/pro-duotone-svg-icons";
import { SsoButton, SsoButtonText } from "./Styled";
import FloModal from "../Components/FloModal";
import { saveCompanySsoSettings } from "./SsoApi";
import { getDataUrl } from "./getDataUrl";

const ellipseGray = require("assets/images/sso/ellipse_gray.svg");

interface SsoSettings {
  ssoEnabled: boolean;
  facebookEnabled: boolean;
  googleEnabled: boolean;
  appleEnabled: boolean;
  customEnabled: boolean;
  pkceEnabled?: boolean;
  wellKnownEndpoint?: string;
  clientId?: string;
  clientSecret?: string;
  apiKey?: string;
  companyName?: string;
  logoUrl?: string;
}

interface OidcProvider {
  clientId: string;
  clientPassword: string;
  serverUrl: string;
  key: string;
  usePkce: boolean;
  providerName: string;
  providerIcon: string;
}

interface SsoSettingsRequest {
  appleAvailable: boolean;
  ssoEnabled: boolean;
  appleEnabled: boolean;
  facebookEnabled: boolean;
  googleEnabled: boolean;
  customEnabled: boolean;
  customProvider: OidcProvider;
}

interface SsoSettingsParameters {
  initialSettings: SsoSettingsRequest;
}

const LogoInput = styled(Input)`
  display: none;
`;

const LogoImage = styled("img")`
  position: absolute;
  top: -100000px;
  left: -100000px;
`;

const isNotBlank = (value: string | undefined): boolean =>
  !!value && value.trim() != "";

const fromSettingsRequest = (request: SsoSettingsRequest): SsoSettings => {
  return {
    ssoEnabled: request.ssoEnabled,
    appleEnabled: request.appleEnabled,
    facebookEnabled: request.facebookEnabled,
    googleEnabled: request.googleEnabled,
    customEnabled: request.customEnabled,
    clientId: request.customProvider?.clientId,
    clientSecret: request.customProvider?.clientPassword,
    wellKnownEndpoint: request.customProvider?.serverUrl,
    apiKey: request.customProvider?.key,
    pkceEnabled: request.customProvider?.usePkce,
    companyName: request.customProvider?.providerName,
    logoUrl: request.customProvider?.providerIcon || ellipseGray,
  };
};

const toSettingsRequest = (state: SsoSettings) => {
  return {
    ssoEnabled: state.ssoEnabled,
    appleEnabled: state.appleEnabled,
    facebookEnabled: state.facebookEnabled,
    googleEnabled: state.googleEnabled,
    customEnabled: state.customEnabled,
    customProvider: {
      clientId: state.clientId,
      clientPassword: state.clientSecret,
      serverUrl: state.wellKnownEndpoint,
      key: state.apiKey,
      usePkce: state.pkceEnabled,
      providerName: state.companyName,
      providerIcon: state.logoUrl,
    },
  };
};

export const SsoSettingsPage: FC<SsoSettingsParameters> = ({
  initialSettings,
}) => {
  const [generalError, setGeneralError] = useState("");
  const [ssoInitiallyEnabled, setSsoInitiallyEnabled] = useState(
    initialSettings.ssoEnabled
  );
  const logoElement = useRef(null);
  const [logo, setLogo] = useState<File | null>(null);
  const [logoError, setLogoError] = useState("");
  const [logoSuccess, setLogoSuccess] = useState(false);
  const [logoSrc, setLogoSrc] = useState("");
  const [confirmDisable, setConfirmDisable] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formState, setFormState] = useState<SsoSettings>(
    fromSettingsRequest(initialSettings)
  );
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    evaluateFormValidity();
  }, [formState, logo]);

  useEffect(() => {
    if (!formState.ssoEnabled) {
      setFormState({
        ...formState,
        appleEnabled: false,
        facebookEnabled: false,
        googleEnabled: false,
        customEnabled: false,
      });
    }
  }, [formState.ssoEnabled]);

  const evaluateFormValidity = () => {
    const ssoEnabled = formState.ssoEnabled;
    const implementationEnabled =
      ssoEnabled &&
      (formState.facebookEnabled ||
        formState.googleEnabled ||
        formState.appleEnabled ||
        formState.customEnabled);
    const companyFieldsNotBlank =
      isNotBlank(formState.wellKnownEndpoint) &&
      isNotBlank(formState.clientId) &&
      isNotBlank(formState.clientSecret) &&
      isNotBlank(formState.apiKey) &&
      isNotBlank(formState.companyName) &&
      isNotBlank(formState.logoUrl);
    const customSsoValid = !formState.customEnabled || companyFieldsNotBlank;

    if (!ssoEnabled || (implementationEnabled && customSsoValid)) {
      return setFormIsValid(true);
    }

    setFormIsValid(false);
  };

  const handleCheckboxChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleInputChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogoChange = (event) => {
    const chosen = event.target.files[0];

    setLogo(chosen);
    setLogoSrc(URL.createObjectURL(chosen));
  };

  const handleLogoLoaded = () => {
    if (logoElement.current == null) {
      return;
    }

    const { naturalHeight, naturalWidth } = logoElement.current;

    if (naturalHeight <= 32 && naturalWidth <= 32) {
      setLogoError("");
      setLogoSuccess(true);
      setFormState({
        ...formState,
        logoUrl: getDataUrl(logoElement.current, logo?.type),
      });
    } else {
      setLogo(null);
      setLogoSuccess(false);
      setLogoError("Uploaded file doesn’t meet logo requirements");
      setFormState({
        ...formState,
        logoUrl: ellipseGray,
      });
    }
  };

  const handleSaveSettingsClicked = () => {
    if (ssoInitiallyEnabled && !formState.ssoEnabled) {
      return setConfirmDisable(true);
    }

    saveSsoSettings();
  };

  const saveSsoSettings = async () => {
    const settingsRequest = toSettingsRequest(formState);

    setSaving(true);

    try {
      const response = await saveCompanySsoSettings(settingsRequest);

      if (response.success) {
        document.cookie = `PLAY_FLASH=success=${encodeURI(
          "Borrower SSO settings have been saved"
        )}; path=/`;
        window.location.href = "/company-settings";
      } else {
        setSaving(false);
        setGeneralError(response.errors.exception);

        window.scrollTo(0, 0);
      }
    } catch (error) {
      setSaving(false);
      setGeneralError(
        "Borrower SSO settings have not been saved. Please contact support@floify.com for help."
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {generalError && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {generalError}
        </Alert>
      )}

      {/* Always shown */}
      <Box sx={{ mt: 3, mb: 3 }}>
        <Box>
          <Typography variant="h3">Borrower SSO settings </Typography>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.gray[500], mb: 1 }}
          >
            Borrower single sign-on (SSO) provides additional log in and account
            creation options for borrowers (e.g. - with Google credentials).
            Traditional Floify log in with email and password will always be
            available.{" "}
          </Typography>
          <Link href="company-settings">Return to your settings</Link>
          <Box sx={{ mt: 3 }}>
            <FormGroup sx={{ pl: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 1 }}
                    onChange={handleCheckboxChange}
                    name="ssoEnabled"
                    checked={formState.ssoEnabled}
                  />
                }
                label="Enable borrower SSO"
              />
            </FormGroup>
          </Box>
        </Box>
        {/* Show if enable borrower sso is checked */}
        {formState.ssoEnabled && (
          <>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="body1"
                sx={{ mb: 2, color: theme.palette.gray[700] }}
              >
                Choose which SSO methods to make available to your borrowers:
              </Typography>
              <FormGroup sx={{ pl: 1, mb: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ mr: 1 }}
                      onChange={handleCheckboxChange}
                      name="googleEnabled"
                      checked={formState.googleEnabled}
                    />
                  }
                  label="Google SSO"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ mr: 1 }}
                      onChange={handleCheckboxChange}
                      name="facebookEnabled"
                      checked={formState.facebookEnabled}
                    />
                  }
                  label="Facebook SSO"
                />
                {initialSettings.appleAvailable && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ mr: 1 }}
                        onChange={handleCheckboxChange}
                        name="appleEnabled"
                        checked={formState.appleEnabled}
                      />
                    }
                    label="Apple ID SSO"
                  />
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ mr: 1 }}
                      onChange={handleCheckboxChange}
                      name="customEnabled"
                      checked={formState.customEnabled}
                    />
                  }
                  label="Company SSO"
                />
              </FormGroup>
              <Typography
                variant="body1"
                sx={{
                  fontStyle: "italic",
                  color: theme.palette.gray[500],
                  mb: 4,
                }}
              >
                Company credentials can be used for SSO login if OpenID Connect
                has been implemented for Company authentication.
              </Typography>
            </Box>
            {/* Shown if company SSO is checked */}
            {formState.customEnabled && (
              <>
                <Divider sx={{ mt: 3, mb: 3 }} />
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ mb: 2, color: theme.palette.gray[700] }}
                  >
                    Company SSO required values:
                  </Typography>

                  <Grid container sx={{ mb: 3 }} spacing={2}>
                    <Grid item xs={6}>
                      <TextInput
                        label="Client ID"
                        placeholder="Input text here"
                        fcsx={{ width: "100%" }}
                        onChange={handleInputChange}
                        name="clientId"
                        value={formState.clientId}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        label="Client secret"
                        placeholder="Input text here"
                        fcsx={{ width: "100%" }}
                        onChange={handleInputChange}
                        name="clientSecret"
                        value={formState.clientSecret}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        label="Well-known endpoint"
                        placeholder="Input text here"
                        fcsx={{ width: "100%" }}
                        onChange={handleInputChange}
                        name="wellKnownEndpoint"
                        value={formState.wellKnownEndpoint}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        label="Key (Unique Identifier)"
                        placeholder="Input text here"
                        fcsx={{ width: "100%" }}
                        onChange={handleInputChange}
                        name="apiKey"
                        value={formState.apiKey}
                      />
                    </Grid>
                  </Grid>

                  <FormControlLabel
                    sx={{ mb: 3 }}
                    control={
                      <Checkbox
                        sx={{ mr: 1, pl: 1 }}
                        onChange={handleCheckboxChange}
                        name="pkceEnabled"
                        checked={formState.pkceEnabled}
                      />
                    }
                    label="Enable Proof Key for Code Exchange (PKCE) for this Company SSO"
                  />

                  <Typography
                    variant="body1"
                    sx={{ mb: 2, color: theme.palette.gray[700] }}
                  >
                    Company SSO button:
                  </Typography>
                  <TextInput
                    placeholder="Name Here"
                    label="Company name"
                    helperText="Max 18 Characters"
                    onChange={handleInputChange}
                    name="companyName"
                    value={formState.companyName}
                    inputProps={{
                      maxlength: 18,
                    }}
                  />

                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.gray[600], mb: 1, mt: 2 }}
                  >
                    Company Logo (favicon)
                  </Typography>
                  <Box>
                    <Stack direction="row">
                      <label htmlFor="contained-button-file">
                        <LogoInput
                          inputProps={{
                            accept:
                              "image/png,image/jpeg,image/svg+xml,image/ico,image/vnd.microsoft.icon,image/icon,image/x-icon",
                          }}
                          id="contained-button-file"
                          type="file"
                          onChange={handleLogoChange}
                        />
                        <PrimaryButton
                          component="span"
                          variant="contained"
                          startIcon={
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ width: 18, height: 18 }}
                            />
                          }
                          sx={{ pl: 4, pr: 4 }}
                        >
                          Upload company logo
                        </PrimaryButton>
                      </label>
                      {logoError && (
                        <>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            style={{
                              width: 18,
                              height: 18,
                              paddingTop: 10,
                              paddingLeft: 8,
                              color: theme.palette.error[500],
                            }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              pt: 1,
                              pl: 1,
                              color: theme.palette.error[500],
                            }}
                          >
                            {logoError}
                          </Typography>
                        </>
                      )}
                      {logoSuccess && (
                        <Stack direction="row" sx={{ ml: 2 }}>
                          <Stack alignContent="center" justifyContent="center">
                            <img
                              src={logoSrc}
                              style={{
                                maxHeight: "32px",
                                maxWidth: "32px",
                              }}
                            />
                          </Stack>

                          <Stack sx={{ ml: 0.8 }}>
                            <Box
                              component="span"
                              sx={{
                                color: theme.palette.gray[700],
                              }}
                            >
                              {logo?.name}
                            </Box>

                            <Typography
                              sx={{
                                lineHeight: "16px",
                                fontSize: "12px",
                                color: theme.palette.success[800],
                                whiteSpace: "nowrap",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  marginRight: 4,
                                  width: 12,
                                  height: 12,
                                  color: theme.palette.success[800],
                                }}
                              />
                              Successfully uploaded
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                    <LogoImage
                      src={logoSrc}
                      ref={logoElement}
                      onLoad={handleLogoLoaded}
                    />
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{
                      mb: 2,
                      color: theme.palette.gray[500],
                      textDecoration: "italic",
                    }}
                  >
                    Image must be no larger than 32x32 pixels.
                  </Typography>

                  {/* Preview Section */}
                  <Typography
                    variant="body1"
                    sx={{ mb: 2, mt: 4, color: theme.palette.gray[700] }}
                  >
                    Company SSO preview and test:
                  </Typography>

                  <Stack direction="row">
                    <Stack sx={{ width: 400 }}>
                      <SsoButton
                        variant="outlined"
                        sx={{
                          mb: 1,
                          maxWidth: 280,
                        }}
                        size="large"
                        disableRipple={true}
                        startIcon={
                          <img
                            src={formState.logoUrl}
                            alt="company-logo"
                            style={{ width: 22, height: 22 }}
                          />
                        }
                      >
                        <SsoButtonText>
                          Continue with{" "}
                          {formState.companyName || "Company Name"}
                        </SsoButtonText>
                      </SsoButton>
                    </Stack>
                    <Link
                      target="_blank"
                      sx={{ padding: 1.5, display: "none" }}
                    >
                      Test Company SSO
                    </Link>
                  </Stack>
                  <Typography
                    variant="caption"
                    sx={{
                      mb: 2,
                      color: theme.palette.gray[500],
                      textDecoration: "italic",
                    }}
                  >
                    This is a preview of how your Company SSO button will
                    display. {/*Be sure to “Test Company SSO” before saving.*/}
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      {/* Always shown */}
      <Stack direction="row">
        <GrayButton
          sx={{
            mr: 1,
          }}
          variant="contained"
          onClick={() => (window.location.href = "/company-settings")}
        >
          Cancel
        </GrayButton>
        <PrimaryButton
          disabled={!formIsValid || saving}
          variant="contained"
          onClick={handleSaveSettingsClicked}
        >
          Save
        </PrimaryButton>
      </Stack>

      <FloModal
        aria-labelledby="modalLabel"
        open={confirmDisable}
        headerStyles={{}}
        headerContent={
          <Stack direction="row" alignItems="center" spacing={2}>
            <FontAwesomeIcon
              icon={faCircleExclamationDuo}
              style={{
                width: 34,
                height: 34,
                marginRight: 10,
                // @ts-ignore
                "--fa-primary-color": theme.palette.error[500],
                // @ts-ignore
                "--fa-secondary-color": theme.palette.error[300],
              }}
            />

            <Typography
              variant="h4"
              sx={{
                flex: 1,
              }}
            >
              Are you sure you want to disable borrower SSO?
            </Typography>

            <FontAwesomeIcon
              icon={faXmark}
              style={{
                width: 16,
                height: 16,
                color: theme.palette.gray[400],
                cursor: "pointer",
              }}
              onClick={() => setConfirmDisable(false)}
            />
          </Stack>
        }
      >
        <Typography
          variant="body1"
          sx={{
            mt: 3,
          }}
        >
          Disabling borrower SSO will require borrowers previously logging in
          with social SSO to create a new Floify password. Borrowers will no
          longer be able to log in with the SSO method that they were previously
          using.
        </Typography>

        <Stack
          direction="row"
          justifyContent="right"
          sx={{
            mt: 3,
          }}
        >
          <GrayButton
            sx={{ mr: 1 }}
            variant="contained"
            color="info"
            onClick={() => setConfirmDisable(false)}
          >
            Cancel
          </GrayButton>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setConfirmDisable(false);
              saveSsoSettings();
            }}
          >
            Disable borrower SSO
          </Button>
        </Stack>
      </FloModal>
    </ThemeProvider>
  );
};

const PrimaryButton = styled((props: { component?: string } & ButtonProps) => (
  <Button {...props} />
))<ButtonProps>`
  color: ${theme.palette.shades.white};

  &.Mui-disabled {
    opacity: 0.5;
    color: ${theme.palette.shades.white};
    background-color: ${theme.palette.primary[500]};
  }
`;

const GrayButton = styled(Button)`
  background-color: ${theme.palette.gray[200]} !important;
  color: ${theme.palette.shades.black} !important;

  &:hover {
    background-color: ${theme.palette.gray[300]} !important;
    color: ${theme.palette.shades.white};
  }
`;
