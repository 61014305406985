import styled from "@emotion/styled";

// we probably want to add props for right/left alingment and maybe some control
// over the top margin
const ButtonGroup = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  & .MuiButton-root {
    margin-right: 10px;
  }
  & * {
    margin-right: 10px;
  }
  & :last-child {
    margin-right: 0;
  }
`;

export default ButtonGroup;
